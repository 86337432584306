import {
  Content,
  Section,
  SkeletonHeaderHome,
  TitleAndDescription,
} from '@ferreri/prever-component-lib';

import { PageLayoutMenuFooter } from '../PageLayoutMenuFooter';
import { SkeletonSection } from './SkeletonSection';

export const SkeletonHomePage = () => {
  return (
    <PageLayoutMenuFooter>
      <Content>
        <SkeletonHeaderHome />
        <Section>
          <TitleAndDescription
            title="Planos Prever Angelus"
            description="Apoio e proteção para todo ciclo de vida"
          />
          <SkeletonSection numberOfCards={4} />
        </Section>
        <Section>
          <TitleAndDescription
            title="Produtos Angeplus"
            description="Usufrua dos benefícios de ser associado"
          />
          <SkeletonSection numberOfCards={4} />
        </Section>
      </Content>
    </PageLayoutMenuFooter>
  );
};
